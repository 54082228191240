<template>
    <div
        class="overflow-hidden rounded-full ring-white dark:ring-gray-800"
        :class="{
            'size-4 ring-2 sm:size-6': size === 'sm',
            'size-6 ring-4 sm:size-8': size === 'md',
            'size-10 ring-4 sm:size-12': size === 'lg',
            'ring-6 size-12 sm:size-16': size === 'xl',
        }"
    >
        <img
            v-if="user.avatarUrl"
            :src="user.avatarUrl"
            :alt="altText"
            :class="{ 'group-hover:opacity-75': hoverable }"
        />
        <div
            v-else
            class="bg-gray-100 text-gray-400 dark:bg-gray-200 dark:text-gray-500"
            :class="{
                'group-hover:text-gray-300 dark:group-hover:text-gray-400':
                    hoverable,
            }"
        >
            <span class="sr-only">{{ altText }}</span>
            <GenericUser />
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import GenericUser from "@/Shared/Svg/GenericUser.vue";

const props = defineProps({
    user: Object,
    size: {
        type: String,
        default: "md",
        validator(value) {
            return ["sm", "md", "lg", "xl"].includes(value);
        },
    },
    hoverable: {
        type: Boolean,
        default: false,
    },
});

const altText = computed(() => {
    if (!props.user.name) {
        return "User's avatar";
    }

    return `${props.user.name}'s avatar`;
});
</script>
