<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="closeModal">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="fixed inset-0 bg-gray-500/75 transition-opacity dark:bg-gray-700/75"
                />
            </TransitionChild>

            <div class="fixed inset-0 z-20 overflow-y-auto">
                <div
                    class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative w-full transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 dark:bg-gray-900 dark:shadow-gray-500/10"
                            :class="width"
                        >
                            <div
                                class="rounded-t-lg bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-900"
                            >
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                                        :class="{
                                            'bg-red-100 dark:bg-red-300':
                                                color === 'red',
                                            'bg-blue-100 dark:bg-blue-300':
                                                color === 'blue',
                                            'bg-green-100 dark:bg-green-300':
                                                color === 'green',
                                        }"
                                    >
                                        <slot name="icon" />
                                    </div>
                                    <div
                                        class="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left"
                                    >
                                        <DialogTitle
                                            as="h3"
                                            class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200"
                                        >
                                            <slot />
                                        </DialogTitle>
                                        <div class="mt-2">
                                            <div
                                                class="text-sm text-gray-500 dark:text-gray-400"
                                            >
                                                <slot name="description" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="rounded-b-lg bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-950"
                            >
                                <slot name="buttons" />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

defineProps({
    color: {
        validator(value) {
            return ["red", "blue", "green"].includes(value);
        },
        default: "blue",
    },
    width: {
        type: String,
        default: "sm:max-w-lg",
    },
});

const emit = defineEmits(["modal-closed"]);

const open = ref(false);

const openModal = () => (open.value = true);

const closeModal = () => {
    open.value = false;
    emit("modal-closed");
};

defineExpose({
    openModal,
    closeModal,
});
</script>
