<template>
    <div
        class="rounded-md p-4"
        :class="{
            'bg-yellow-50 dark:bg-yellow-950': type === 'warning',
            'bg-green-50 dark:bg-green-950': type === 'success',
            'bg-blue-50 dark:bg-blue-950': type === 'info',
            'bg-red-50 dark:bg-red-950': type === 'error',
        }"
    >
        <div class="flex">
            <div
                class="flex-shrink-0"
                :class="{
                    'text-yellow-400 dark:text-yellow-600': type === 'warning',
                    'text-green-400 dark:text-green-600': type === 'success',
                    'text-blue-400 dark:text-blue-600': type === 'info',
                    'text-red-400 dark:text-red-600': type === 'error',
                }"
            >
                <slot name="icon">
                    <component :is="icon" class="size-5" aria-hidden="true" />
                </slot>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <div
                    class="flex-1 text-sm"
                    :class="{
                        'text-yellow-700 dark:text-yellow-400':
                            type === 'warning',
                        'text-green-700 dark:text-green-400':
                            type === 'success',
                        'text-blue-700 dark:text-blue-400': type === 'info',
                        'text-red-700 dark:text-red-400': type === 'error',
                    }"
                >
                    <slot />
                </div>
                <p v-if="$slots.link" class="mt-3 text-sm md:ml-6 md:mt-0">
                    <slot name="link" />
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import {
    ExclamationTriangleIcon,
    InformationCircleIcon,
    CheckCircleIcon,
    XCircleIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps({
    type: {
        vaidator(value) {
            return ["info", "succes", "warning", "error"].includes(value);
        },
        default: "info",
    },
});

const icon = computed(() => {
    if (props.type === "success") {
        return CheckCircleIcon;
    }

    if (props.type === "warning") {
        return ExclamationTriangleIcon;
    }

    if (props.type === "error") {
        return XCircleIcon;
    }

    return InformationCircleIcon;
});
</script>
